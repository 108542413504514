export interface IJob {
    title: string,
    company: string,
    location: string,
    start: number,
    end: number,
    description: string[],
    achievements: string[],
    topics: string[],
    type: string,
    link?: string,
}

export const jobs: Array<IJob> = [

];

export interface IProject {
    title: string,
    url: string | null,
    github: string | null,
    description: Array<string>,
    descriptionImages: Array<String>
    descriptionVideos: Array<String>
    image: string,
    topics: Array<string>
    tools: Array<string>
    start: number,
    end: Number,
    npm: string | null,
    hasActions: boolean
}

export const projects: IProject[] = [
    {
        title: 'SeniorMusic Bot',
        url: null,
        github: 'https://github.com/Lucasmellof/SeniorMusic',
        description: [
            'This bot was made to prove my knowledge in the SeniorTeam developer test.',
        ],
        descriptionImages: [
            'https://lucasmellof.vercel.app//images/portfolio/senior/music-embed.png',
            'https://lucasmellof.vercel.app//images/portfolio/senior/now-playing.png',
            'https://lucasmellof.vercel.app//images/portfolio/senior/play.png',
            'https://lucasmellof.vercel.app//images/portfolio/senior/settings.png',
            'https://lucasmellof.vercel.app//images/portfolio/senior/status.png'
        ],
        descriptionVideos: [],
        image: 'https://pbs.twimg.com/profile_images/1342904521580826624/27_hTs8H.jpg',
        topics: [
            'Discord',
            'Discord Bot',
            'MusicBot'
        ],
        tools: [
            'Kotlin',
            'JDA',
            'MongoDB',
            'LavaPlayer'
        ],
        start: 1596835500000,
        end: 1596900600000,
        npm: null,
        hasActions: true
    },
    {
        title: 'FinalCraft Bot',
        url: 'https://finalcraft.com.br/',
        github: null,
        description: [
            'This bot is for the FinalCraft server network, the bot was not made 100% by me, I just helped make some systems.'
        ],
        descriptionImages: [
            'https://lucasmellof.vercel.app//images/portfolio/final/close-maintenance.png',
            'https://lucasmellof.vercel.app//images/portfolio/final/fancy-embed.png',
            'https://lucasmellof.vercel.app//images/portfolio/final/feature-embed.png',
            'https://lucasmellof.vercel.app//images/portfolio/final/open-maintenance.png',
            'https://lucasmellof.vercel.app//images/portfolio/final/server-embed.png'
        ],
        descriptionVideos: [],
        image: 'https://finalcraft.com.br/docs/img/gandalf-metade.jpg',
        topics: [
            'Minecraft Server',
            'Discord',
            'Discord Bot',
        ],
        tools: [
            'Java',
            'JDA',

        ],
        start: 1596006000000,
        end: 0,
        npm: null,
        hasActions: true
    },
    {
        title: 'Speedy',
        url: null,
        github: 'https://github.com/Lucasmellof/Speedy-rewrite',
        description: [
            'Speedy was a simple bot that I developed for SpeedTech when I worked there.',
        ],
        descriptionImages: [
            'https://lucasmellof.vercel.app//images/portfolio/speedy/ticket.png',
            'https://lucasmellof.vercel.app//images/portfolio/speedy/crashreader.png'
        ],
        descriptionVideos: [
            'https://youtube.com/embed/mmpsyo2zLjE'
        ],
        topics: [
            'Minecraft Server',
            'Discord',
            'Discord Bot',
        ],
        image: 'https://lucasmellof.vercel.app//images/portfolio/ST.png',
        tools: [
            'Kotlin',
            'JDA',
        ],
        start: 1595660400000,
        end: 1596006000000,
        npm: null,
        hasActions: true
    },
    {
        title: 'Random bot stuff',
        url: null,
        github: null,
        description: [
            'Bot things I did to test or learn',
        ],
        descriptionImages: [
            'https://lucasmellof.vercel.app//images/portfolio/server.png'
        ],
        descriptionVideos: [
            'https://youtube.com/embed/vKz0IqbP4dw',
            'https://youtube.com/embed/OByXT5E3lTg'
        ],
        image: 'https://lucasmellof.vercel.app//logo512.png',
        topics: [
            'Discord',
            'Discord Bot',
        ],
        tools: [
            'Kotlin',
            'Java',
            'MongoDB',
            'JDA',
        ],
        start: 1584774000000,
        end: 0,
        npm: null,
        hasActions: true
    },
    {
        title: 'TopTab',
        url: null,
        github: null,
        description: [
            'Fabric mod made to modify players tab',
        ],
        descriptionImages: [
            'https://lucasmellof.vercel.app//images/portfolio/top/toptab.png'
        ],
        descriptionVideos: [
        ],
        image: 'https://lucasmellof.vercel.app//images/portfolio/TopTab.png',
        topics: [
            'Fabric'
        ],
        tools: [
            'Java',
            'Fabric',
        ],
        start: 1624469640000,
        end: 1624642440000,
        npm: null,
        hasActions: true
    },
    {
        title: 'TopDeaths',
        url: null,
        github: null,
        description: [
            'Fabric mod made to display player death in tab',
        ],
        descriptionImages: [
            'https://lucasmellof.vercel.app//images/portfolio/top/toptab.png'
        ],
        descriptionVideos: [
        ],
        image: 'https://lucasmellof.vercel.app//images/portfolio/TopDeaths.png',
        topics: [
            'Fabric'
        ],
        tools: [
            'Java',
            'Fabric',
        ],
        start: 1624469640000,
        end: 1624642440000,
        npm: null,
        hasActions: true
    }
];

export interface ISkill {
    title: string,
    proficiency: string,
    topic: string[],
    icon?: string,
}

export const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const skills: ISkill[] = [
    {
        title: 'Java',
        proficiency: '60%',
        topic: [
            'Coding',
        ],
        icon: 'java',
    },
    {
        title: 'Kotlin',
        proficiency: '65%',
        topic: [
            'Coding',
        ],
        icon: 'Kotlin',
    },
    {
        title: 'Python',
        proficiency: '30%',
        topic: [
            'Coding',
        ],
        icon: 'Python',
    },
    {
        title: 'JavaScript',
        proficiency: '55%',
        topic: [
            'Coding',
        ],
        icon: 'JavaScript',
    },
    {
        title: 'TypeScript',
        proficiency: '45%',
        topic: [
            'Coding',
        ],
        icon: 'TypeScript',
    },
];
export interface ISoftwareSkill {
    name: string,
    icon: string,
}
export const softwareSkill: ISoftwareSkill[] = [
    {
        name: 'Java',
        icon: 'fab fa-java'
    },
    {
        name: 'Kotlin',
        icon: 'fab fa-java'
    },
    {
        name: 'Python',
        icon: 'fab fa-python'
    },
    {
        name: 'JavaScript',
        icon: 'fab fa-js'
    },
    {
        name: 'TypeScript',
        icon: 'fab fa-js'
    },
    {
        name: 'SQL databases',
        icon: 'fas fa-database'
    },
    {
        name: 'MongoDB',
        icon: 'fas fa-database'
    },
]
export interface IFrameworkSkill {
    name: string,
    icon?: string,
}
export const frameworkSkill: IFrameworkSkill[] = [
    {
        name: 'Linux',
        icon: 'fab fa-linux'
    },
    {
        name: 'Android',
        icon: 'fab fa-android'
    },
    {
        name: 'GitHub',
        icon: 'fab fa-github'
    },
    {
        name: 'NodeJS',
        icon: 'fab fa-node'
    },
    {
        name: 'Yarn',
        icon: 'fab fa-yarn'
    },
    {
        name: 'Nginx',
        icon: 'fas fa-server'
    },
]