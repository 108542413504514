import React from "react";
import {softwareSkill, frameworkSkill} from '../../../config';

import "./SoftwareSkill.css";

export default function SoftwareSkill() {
    return (
        <div>
            <div>
                <ul className="dev-icons">
                    {softwareSkill.map((skills, index) => {
                        return (
                            <li className="software-skill-inline" key={index}>
                                <i className={skills.icon}/>
                                <p>{skills.name}</p>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div>
                <ul className="dev-icons">
                    {frameworkSkill.map((skills, index) => {
                        return (
                            <li className="software-skill-inline" key={index}>
                                <i className={skills.icon}/>
                                <p>{skills.name}</p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}
