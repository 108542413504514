import {
    createStyles,
    makeStyles,
    Theme,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '--delay': 0,
            padding: '1rem .5rem',
        },
        line: {
            display: 'block',
            width: '10rem',
            height: '0.3rem',
            borderRadius: '.5rem',
            background: '#6C63FF',
        },
        first: {
            marginLeft: '0',
            animation: 'hide calc(var(--delay) * .1s), enter-left .3s ease calc(var(--delay) * .1s)',
        },
        second: {
            marginTop: '1.5rem',
            marginLeft: '7rem',
            animation: 'hide calc((var(--delay) * .1s) + .1s), enter-left .3s ease calc((var(--delay) * .1s) + .1s)',
        },
    }),
);

export default useStyles;
