import React from 'react';
import {Link} from "react-router-dom";

import {skills} from '../../config';
// @ts-ignore
import BackIcon from '../../assets/icons/back.svg';

import useStyles from './style';
import SoftwareSkill from "./components/SoftwareSkill";

const styles = require('./styles.css');
export default function Skills() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Link
                className={classes.back}
                to="/?page=skills">
                <img
                    className={classes.backIcon}
                    src={BackIcon} alt={'alt-text'}/>
            </Link>
            <div className={classes.headerWrapper}>
                <h1 className={classes.title}>
                    Skills
                </h1>

            </div>

            <span className={classes.mainDivider}/>
            <div className={styles.skillsMainDiv}>
                <div className={styles.skillsImageDiv}>
                    <SoftwareSkill/>
                </div>
                <div className={styles.skillsTextDiv}>

                </div>


                <div className={styles.skillsContainer}>
                    <div>
                        {skills.map((skill, index) => {
                            const progressStyle = {
                                width: skill.proficiency
                            };
                            return (
                                <div className={classes.skill} key={index}>
                                    <p>{skill.title}</p>
                                    <div className={classes.meter}>
                                        <span className={classes.meterSpan} style={progressStyle}/>
                                    </div>
                                </div>
                            );
                        })

                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
