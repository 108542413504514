import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import Divider from '../../../../components/ui/divider/Divider';
import ContactGraphic from '../../../../assets/graphics/Contact';
import { SectionProps } from '../../index';

import useStyles from './style';

export default function Education(props: SectionProps) {
  const { ref, inView } = useInView({
    threshold: .2,
  });

  useEffect(() => {
    props.showChange(props.index, inView);
  }, [inView, props]);

  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={classes.root}>
      <div className={classes.wrapper}>
        {inView &&
          <div className={classes.content}>
            <h1 className={classes.title}>
              Contact Me
            </h1>

            <Divider />
            <div className={classes.contact}>

              <a className={classes.subTitle} href={'https://discordapp.com/users/213680997546459136'}>
                <i className="fab fa-discord"/>
                {' carmello#0760'}
              </a>
            </div>

          </div>
        }

        <div className={classes.graphicWrapper}>
          {inView &&
            <ContactGraphic />
          }
        </div>
      </div>
    </div>
  );
}
