import {
    createStyles,
    makeStyles,
    Theme,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 'calc(100% - 4rem)',
            maxWidth: '150rem',
            margin: '10rem auto 0',
            paddingBottom: '3rem',
        },
        mainDivider: {
            animation: 'hide .4s, enter-left .4s ease .4s',
            display: 'block',
            margin: '1rem 0 2rem',
            width: '100%',
            height: '.4rem',
            borderRadius: '.5rem',
            background: '#6C63FF',
        },
        headerWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '3rem',
            flexWrap: 'wrap',
        },
        back: {},
        backIcon: {
            display: 'block',
            width: '6rem',
            height: '6rem',
            marginBottom: '1rem',
            marginRight: '1rem',
            transition: 'all .2s ease',
            [theme.breakpoints.between('xs', 625)]: {
                width: '3rem',
                height: '3rem',
            },
            [theme.breakpoints.between(625, 1600)]: {
                width: '4rem',
                height: '4rem',
            },
            '&:hover': {
                opacity: .6,
                transform: 'scale(1.1, 1.1)',
            },
        },
        title: {
            animation: 'hide .2s, enter-left .2s ease .2s',
            [theme.breakpoints.between('xs', 625)]: {
                fontSize: '4rem',
            },
            [theme.breakpoints.between(625, 990)]: {
                fontSize: '6rem',
            },
            [theme.breakpoints.between(990, 1600)]: {
                fontSize: '6rem',
            },
            [theme.breakpoints.between(1600, 'xl')]: {
                fontSize: '8rem',
            },
        },
        formControl: {
            animation: 'hide .2s, enter-left .2s ease .2s',
            margin: theme.spacing(1),
            minWidth: 120,
            width: 'calc(40vw - 2rem)',
            maxWidth: 200,
            color: 'white',
            fontSize: '16px',
        },
        controls: {
            flexWrap: 'wrap',
            display: 'flex',
            alignItems: 'center',
        },
        projectWrapper: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginBottom: '2rem',
        },
        skillsContainer: {
            display: 'flex',
            padding: '20px 10px',
            margin: '0px auto',
            marginTop: '2rem'
        },
        graphicWrapper: {
            display: 'flex',
            position: 'relative',

            [theme.breakpoints.between('xs', 625)]: {
                alignSelf: 'center',
                width: '70%',
                marginTop: '6rem',
            },
            [theme.breakpoints.between(625, 900)]: {
                alignSelf: 'center',
                width: '55%',
                marginTop: '4rem',
            },
            [theme.breakpoints.between(900, 'lg')]: {
                alignSelf: 'center',
                width: '35%',
                marginTop: '4rem',
            },
            [theme.breakpoints.between('lg', 2000)]: {
                alignSelf: 'flex-start',
                marginTop: 'calc((100vh - 45%) / 2)',
                width: '40%',
            },
            [theme.breakpoints.between(2000, 'xl')]: {
                alignSelf: 'flex-start',
                marginTop: 'calc((100vh - 50%) / 2)',
                width: '50%',
            },
        },
        skill: {
            lineHeight: '3.5vw'
        },
        meter: {
            height: '20px',
            position: 'relative',
            background: 'rgb(243, 239, 239)',
            borderRadius: '25px'
        },
        meterSpan: {
            display: 'block',
            height: '100%',
            borderTopRightRadius: '6px',
            borderBottomRightRadius: '6px',
            borderTopLeftRadius: '20px',
            borderBottomLeftRadius: '20px',
            backgroundColor: '#6C63FF',
            position: 'relative',
            overflow: 'hidden'
        }
    }));

export default useStyles;
