import React from 'react';
import {
    AppBar as MaterialAppBar,
    Tooltip,
} from '@material-ui/core';
import {Link} from "react-router-dom";

import GithubIcon from '../../../assets/icons/github.svg';

import useStyles from './style';

function AppBar() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <MaterialAppBar
                color="transparent"
                position="fixed">
                <div className={classes.toolBar}>
                    <Link
                        className={classes.title}
                        to="/">
                        Lucasmellof
                    </Link>

                    <div className={classes.iconWrapper}>
                        <Tooltip title="Github">
              <span>
                <a
                    href="https://github.com/Lucasmellof"
                    target="_blank"
                    rel="noreferrer">
                  <img
                      className={classes.icon}
                      src={GithubIcon}
                      alt="Lucasmellof"/>
                </a>
              </span>
                        </Tooltip>
                    </div>
                </div>
            </MaterialAppBar>
        </React.Fragment>
    )
}

export default AppBar;
